.pasos {
    display: flex;
    overflow-x: auto;
    gap: 15px;
    padding-bottom: 15px;
    scrollbar-width: thin;
    scrollbar-color: #888 #f5f5f5;
    &::-webkit-scrollbar {
        height: 6px;
        background-color: transparent; /* Fondo transparente para la barra */
        padding: 10px 10px;
    }
    &::-webkit-scrollbar-thumb {
        // background-color: #888;
        border-radius: 5px; /* Bordes redondeados */
        background-color: #4f6f88;
    }
    &::-webkit-scrollbar-track {
        // background-color: #f5f5f5;
        border-radius: 5px; /* Bordes redondeados */
        background-color: transparent; /* Fondo transparente para el track */
    }
}

.subpasos {
    display: flex;
    flex-direction: row;
    // background-color: green;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 6px;
        background-color: transparent; /* Fondo transparente para la barra */
        padding: 10px 10px;
    }
    &::-webkit-scrollbar-thumb {
        // background-color: #888;
        border-radius: 5px; /* Bordes redondeados */
        background-color: #4f6f88;
    }
    &::-webkit-scrollbar-track {
        // background-color: #f5f5f5;
        border-radius: 5px; /* Bordes redondeados */
        background-color: transparent; /* Fondo transparente para el track */
    }
}
