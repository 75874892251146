.billing-container {
    // background-color: #1d1d26;
    // padding: 20px;
    // font-family: Raleway, sans-serif;
    // max-width: 1000px;
    margin: 0 auto;
}

.billing-header {
    font-size: 24px;
    margin-bottom: 20px;
    color: #f08a3b;
}

.billing-item {
    // background-color: #3d4f65;
    // background-color: $quaternaryColor;

    border-radius: 8px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    // padding: 15px;
    margin-bottom: 20px;
}

.billing-item:not(:last-child) {
    // border-bottom: 1px solid $quaternaryColor;
}



.product-title {
    font-size: 18px;
    color: #f08a3b;
    font-weight: bold;
}

.product-description {
    color: #f9f9f9;
}

.product-date {
    color: #f9f9f9;
    font-size: 12px;
    margin-top: 5px;
}
