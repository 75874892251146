.main-container {
    display: flex;
    flex-direction: column;
    color: white;
}

.input-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
}

.dark-input {
    background-color: #3d4f65;
    color: white;
    border: none;
    padding: 5px 10px;

    border-radius: 5px;
}

.dark-input:disabled {
    margin-left: -10px;
    background-color: #1d1d26;
    color: #fff;
    opacity: 1;
}

.dark-input:focus {
    color: #fff;
    background-color: #3d4f65;
    border-color: #f08a3b;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(240, 138, 59, 0.25);
}

// .dark-input,
// select.dark-input {
//     height: 40px; /* Puedes ajustar este valor a lo que mejor se vea en tu diseño */
//     padding: 5px 10px;
//     border: none;
//     box-sizing: border-box;
// }

select.dark-input {
    height: 36px; /* Ajusta este valor según la altura exacta de los otros inputs */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    align-items: center;
    display: flex;
}

.relation-wrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #323343;
    padding: 1rem 0;
}

.partner-label {
    flex: 2;
    padding-right: 1rem;
    align-self: self-start;
    color: white;
    display: flex;
    align-items: center;
}

.partner-label svg {
    margin-top: -2px;
}

.details-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.input-label {
    display: inline-block;
    font-weight: 500;
    margin-bottom: 0.3rem;
    color: #f08a3b;
    font-size: 0.9em; /* Smaller font size */
}

.input-group {
    display: flex;
    justify-content: space-between;
}

.input-container {
    flex: 1;
    margin-right: 10px; /* Ajusta este valor según tu necesidad */
}

/* Estilos responsive (mobile) */
@media screen and (max-width: 767px) {
    .relation-wrapper {
        flex-direction: column;
    }

    .partner-label,
    .details-wrapper {
        flex: none;
        width: 100%;
    }

    .input-group {
        flex-direction: column;
    }

    .input-container {
        margin-right: 0;
        margin-bottom: 10px; /* Si deseas añadir un poco de espacio entre inputs en móviles */
    }
}





