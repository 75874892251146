// Here you can add other styles
@import "../components/actionBar/style/actionBarStyles.scss";



/* Ocultar flechas en input de número para Chrome, Safari y Opera */
.input-number::-webkit-outer-spin-button,
.input-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Ocultar flechas en input de número para Firefox */
.input-number[type=number] {
    -moz-appearance: textfield;
}