$principalColor: #3d4f65;
$secondaryColor: #f08a3b;
$tertiaryColor: #1d1d26;
$tertiaryColor2: rgb(46, 46, 58) 6;
$quaternaryColor: #323343;

.company-card {
    font-family: "Roboto", sans-serif;
    // border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.817);
    max-width: 600px;
    margin: 20px auto;
    background-color: $principalColor;
    color: white;

}

.company-name {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
    color: white;
}

.company-type,
.company-description,
.company-address,
.company-cif,
.company-email,
.company-phone,
.company-mobile {
    font-size: 14px;
    // color: #666;
    color: white;
    margin-bottom: 8px;
}

.company-overview-header {
    background-color: #f0f0f0;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.company-overview-header h1 {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    color: #333;
    margin: 0;
    padding: 0;
}

.company-relation-list {
    margin-top: 20px;
}

.relation-gap {
    margin-bottom: 20px;
}

.comment-note {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #666;
    margin-top: 5px;
}
