// If you want to override variables do it here
@import "variables";

$enable-ltr: true; /* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true; /* stylelint-disable-line scss/dollar-variable-default */

// Import styles
@import "@coreui/coreui-pro/scss/coreui";
@import "@coreui/coreui-pro/scss/themes/dark";

@import "layout";

// If you want to add custom CSS you can put it here.
@import "custom";

// Estilos de CORE UI modificados

$principalColor: #3d4f65;
$secondaryColor: #f08a3b;
$tertiaryColor: #1d1d26;
$quaternaryColor: #323343;

// Es necesario porque por defecto el señor de la librería le ha puesto un padding más grande que mi cabeza
.vr {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

// Cambia de manera global el el color del sidebar
.sidebar {
    // ORIGINAL NIMO
    // --cui-sidebar-bg: #0a1215 !important;
    // COLOR ALGO MÁS CLARO
    --cui-sidebar-bg: #1d1d26 !important;
}

// Cambia de manera global el color de los botones marcados de la paginación
.pagination {
    --bs-pagination-active-bg: #101d23 !important;
    --bs-pagination-active-border-color: #101d23 !important;

    // Páginas no seleccionadas
    --bs-pagination-color: #101d23 !important;
    // hover
    --bs-pagination-hover-color: #616161 !important;
}

.calendar {
    // --cui-calendar-cell-selected-bg: #101d23 !important;
    // --cui-calendar-cell-range-bg: rgba(16, 29, 35, 0.1) !important;
    // // hover
    // --cui-calendar-cell-hover-bg: #c3c3c3 !important;
    // --cui-calendar-cell-range-hover-bg: #c3c3c325 !important;

    // COMIENZO
    --cui-calendar-cell-selected-bg: #3d4f65 !important;
    --cui-calendar-cell-range-bg: #a4afbd !important;
    // // hover
    --cui-calendar-cell-hover-bg: #677a90 !important;
    --cui-calendar-cell-range-hover-bg: #c3c3c325 !important;

}

// Checkbox de los multiselect
.form-multi-select {
    --cui-form-multi-select-option-selected-indicator-bg: #101d23 !important;
    --cui-form-multi-select-option-selected-indicator-border-color: #101d23 !important;
}

// Checkbox de las tablas (Afecta al check principal) Estos checks son generados por mí
.form-check-input:checked {
    background-color: #101d23 !important;
    border-color: #101d23 !important;
}

// Checkbox de las tablas (Afecta al check principal) Estos checks son generados por mí
.form-check-input:indeterminate {
    background-color: #101d23 !important;
    border-color: #101d23 !important;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$principalColor: #3d4f65;
$secondaryColor: #f08a3b;
$tertiaryColor: #1d1d26;
$quaternaryColor: #323343;

// :root {
//     --cui-form-multi-select-focus-border-color: green !important;
// }

// .dropdown.show.picker.form-multi-select {
//     --cui-form-multi-select-focus-border-color: #f08a3b !important;  /* o cualquier otro color que desees */
// }

// .form-multi-select.show {
//     box-shadow: 0 0 0 0.25rem rgb(59, 201, 3);
// }

.body {
    font-family: "Raleway", sans-serif !important;
    // background-color: $tertiaryColor;
    background-color: $principalColor;
}

.footer {
    font-family: "Raleway", sans-serif !important;

    // background-color: $tertiaryColor;
    background-color: $principalColor;

    color: white;
    // border-top: 1px solid $principalColor;
    border-top: 1px solid $tertiaryColor;
}

.header {
    font-family: "Raleway", sans-serif !important;

    // background-color: $tertiaryColor;
    background-color: $principalColor;

    color: white;
    margin-bottom: 0px;
}

.wrapper {
    background-color: pink;
}

.cHeaderWithColor {
    border-bottom: 20px solid red;
    // margin-bottom: 0;
}
