.payment-completed {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #f7fcfc;
    border: 1px solid #3dcc85;
    border-radius: 10px;
}

.payment-completed svg {
    width: 40px;
    height: 40px;
    fill: #3dcc85;
}

.payment-message {
    margin-left: 8px;
    font-size: 16px;
    color: #3dcc85;
}

.positive-note {
    display: block;
    margin-top: 5px;
    font-size: 12px;
    color: #666;
}

.payment-error {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #fee7e7;
    border: 1px solid #dc3545;
    border-radius: 10px;
}

.payment-error svg {
    width: 40px;
    height: 40px;
    fill: #dc3545;
}

.payment-message-error {
    margin-left: 8px;
    font-size: 16px;
    color: #dc3545;
}

.error-note {
    display: block;
    margin-top: 5px;
    font-size: 12px;
    color: #dc3545;
}

#cardholderName::placeholder {
    color: #aab7c4;
}
