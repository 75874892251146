.company-dropdown {
    position: relative;
    // background-color: #213444;
}

.selected-company {
    margin: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1c2f3d;
    padding: 8px 16px;
    border-radius: 8px;
    color: #ffffff;

    &:hover {
        background-color: #243a4c;
    }
}

.company-list {
    position: relative;
    top: 100%;
    left: 0;
    z-index: 10;
    width: 100%;
    min-height: 40px;
    max-height: 200px; // Establece una altura máxima de 400px
    overflow-y: auto; // Añade un scrollbar vertical si el contenido supera la altura máxima
    background-color: #213444;
}

.company-item {
    margin: 0px 5px;
    padding: 10px;
    background-color: #2a4153;
    color: #ffffff;
    cursor: pointer;

    &:hover {
        background-color: #243a4c;
    }

    &:first-child {
        margin-top: 10px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    // &:nth-last-child(2) {
    //     border-bottom-left-radius: 8px;
    //     border-bottom-right-radius: 8px;
    // }
}

/* Estilos para el botón de Crear Compañía */
.create-company-btn {
    background-color: #f08a3b;
    color: white;
    padding: 7px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 30px;
    display: block;
    width: 90%;
    text-align: center;

    &:hover {
        background-color: #e16d14;
    }
}

/* Estilos para el mensaje debajo del botón */
.company-creation-note {
    font-size: 11px;
    color: #f08a3b;
    text-align: center;
    margin: 5px 5px;
}

// SCROLL

/* Estilos base para la barra de desplazamiento */
::-webkit-scrollbar {
    width: 4px; /* Hacer la barra más fina */
    background-color: transparent; /* Fondo transparente para la barra */
    padding: 10px 10px;
}

/* Estilo para el pulgar (parte móvil) de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
    border-radius: 5px; /* Bordes redondeados */
    background-color: #4f6f88;
}

/* Estilo para el track (parte estática) de la barra de desplazamiento */
::-webkit-scrollbar-track {
    border-radius: 5px; /* Bordes redondeados */
    background-color: transparent; /* Fondo transparente para el track */
}

/* Aplicar estilos solo al componente compony-list */
// .company-list::-webkit-scrollbar {
//     width: 4px; /* Hacer la barra más fina */
//     background-color: transparent; /* Fondo transparente para la barra */
//     padding: 10px 10px;
//     /* Si necesitas especificaciones adicionales para el componente, agrégales aquí */
// }

// .company-list::-webkit-scrollbar-thumb {
//     /* Especificaciones adicionales para el pulgar del componente */
//     border-radius: 5px; /* Bordes redondeados */
//     background-color: #4f6f88;
// }

// .company-list::-webkit-scrollbar-track {
//     /* Especificaciones adicionales para el track del componente */
//     border-radius: 5px; /* Bordes redondeados */
//     background-color: transparent; /* Fondo transparente para el track */
// }
