$principalColor: #3d4f65;
$secondaryColor: #f08a3b;
$tertiaryColor: #1d1d26;
$tertiaryColor2: #26262f;
$quaternaryColor: #323343;

.step-card {
    flex: 0 0 250px; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    border-radius: 8px;
    background-color: white; 
    position: relative; /* Para posicionar absolutamente el estado */

    &:hover {
        background-color: $quaternaryColor;
        color: white;
    }

    h4 {
        margin: 0;
        font-size: 18px;
        color: #333;
        display: flex;
        justify-content: space-between; /* Para separar "Paso N°" y "Estado" */
        align-items: center;
    }

    .step-name {
        font-size: 16px;
        margin: 10px 0;
    }

    .step-description {
        font-size: 14px;
        color: #555;
    }

    .step-status {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 14px;
        color: #555;
    }
}
