.step-row.horizontal {
    display: flex;
    justify-content: space-between;
}

.step-row.vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step {
    min-width: 40px;
    min-height: 40px;
    overflow: hidden;
    border-radius: 50%; 
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    position: relative;
    cursor: not-allowed;
    margin: 10px;
    transition: transform 0.3s ease, background-color 0.3s ease; /* Animación de transición */
}

.step.clickable {
    cursor: pointer;
    transform: scale(1.1); /* Efecto de "zoom" para mostrar que es clickeable */
}

.step.active {
    background-color: #007bff;
    color: white;
}

.step-name {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}
