$principalColor: #3d4f65;
$secondaryColor: #f08a3b;
$tertiaryColor: #1d1d26;
$tertiaryColor2: rgb(46, 46, 58) 6;
$quaternaryColor: #323343;

.container-substep {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // width: 390px;
  // height: 210px;
  width: 300px;
  height: 180px;
  // background-color: rgba(245, 245, 245, 0.9);
  background-color: #1d1d26;

  box-shadow: 0 1.5px 4px 2px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  margin: 8px;

}

.container-substep-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // color: white;
  color: $secondaryColor;
  font-size: 15px;
}

.container-substep-footer {
  // background-color: #404040;
  background-color: $quaternaryColor;

  color: white;
  // color: $secondaryColor;
  font-weight: 500;
  padding: 5px 10px;
  text-align: right;
  border-top: 1px solid rgb(112, 112, 112);
  box-shadow: 0 1.5px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.triangle-invisible {
  cursor: pointer;
  border-radius: 8px 0px 0px 0px;
  position: relative; // Añadir esta línea
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-color: rgba(177, 22, 74, 0) transparent transparent transparent;
  top: 0;
  left: 0;
}

.triangle-indicator {
  cursor: pointer;
  border-radius: 8px 0px 0px 0px;
  position: relative; // Añadir esta línea
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-color: rgb(233, 14, 87) transparent transparent transparent;
  top: 0;
  left: 0;
  &:hover {
    border-color: rgb(239, 64, 122) transparent transparent transparent;
  }
}

.triangle-indicator-admin {
  cursor: pointer;
  border-radius: 8px 0px 0px 0px;
  position: relative; // Añadir esta línea
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-color: rgb(241, 158, 5) transparent transparent transparent;
  top: 0;
  left: 0;

  &:hover {
    border-color: rgb(255, 192, 76) transparent transparent transparent;
  }
}

.triangle-indicator-admin > .c-icon {
  position: absolute;
  top: -46.5px; // Estos valores pueden ser ajustados según la necesidad
  left: 2px;
  color: rgb(0, 0, 0);
}

.triangle-indicator > .c-icon {
  position: absolute;
  top: -46.5px; // Estos valores pueden ser ajustados según la necesidad
  left: 2px;
  color: rgb(0, 0, 0);
}
