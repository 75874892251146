$principalColor: #3d4f65;
$secondaryColor: #f08a3b;
$tertiaryColor: #1d1d26;
$quaternaryColor: #323343;

.document-section {
    background-color: $quaternaryColor;
    border-radius: 5px;
    border-color: $secondaryColor;
    padding: 1.5rem;
    color: white;
    margin: 10px;
    max-width: 600px;

    .document-title {
        color: $secondaryColor;
        font-size: 18px;
        padding-bottom: 10px;
        font-weight: bold;
        margin-bottom: 40px;
        border-bottom: 1px solid $tertiaryColor;
    }

    .document-description {
        margin-bottom: 30px;
        font-size: 16px;
    }

    .document-list {
        .document-item {
            font-size: 13px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem;
            border: 1px solid $tertiaryColor;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;
            margin-bottom: 10px;

            &:hover {
                background-color: $tertiaryColor;
            }

            &:first-child {
                // border-top: none;
            }

            .document-icon {
                margin-left: 1rem;
            }
        }
    }
}

.button-perla-document-page {
    background: linear-gradient(0deg, $secondaryColor, $secondaryColor); /* Gradiente que genera el efecto de brillo */
    border: none;
    border-radius: 30%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-weight: bold;
    margin: 0 10px;
    padding: 4px 12px; /* Reducimos el padding para hacerlo más pequeño */
    transition: 0.2s;
}
