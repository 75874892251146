$principalColor: #3d4f65;
$secondaryColor: #f08a3b;
$tertiaryColor: #1d1d26;
$tertiaryColor2: rgb(46, 46, 58) 6;
$quaternaryColor: #323343;

.choiceButton {
    padding: 5px 50px;
    border-radius: 4px;
    border: 2px solid #007bff; /* color azul para el borde */
    background-color: transparent;
    font-size: 18px;
    cursor: pointer;
    transition:
        background-color 0.3s ease,
        transform 0.3s ease,
        color 0.3s ease;
    margin: 0 20px; /* separación entre los botones */
    color: #007bff; /* color azul para el texto */
}

.choiceButton:focus {
    outline: none;
    transform: scale(0.97);
}

.choiceButton:hover {
    background-color: #007bff; /* color azul para el fondo en hover */
    color: white;
}

//

.fileUploadContainer {
    // background-color: #007bff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;

    .fileCounter {
        margin-left: 20px;
        font-size: 36px;
        font-weight: bold;
        color: #333;
    }
}

.autocompleteContainer {
    margin-top: 20px;
    width: 100%;
}

.choiceButton.goBack {
    background-color: lightgray;
    margin-bottom: 20px;
}

// document

.documentRow {
    padding: 15px;
    border: 1px solid #e0e0e0;
    // margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f8f8;

    .icon-close {
        width: 24px;
        height: auto;
        cursor: pointer;
        fill: $secondaryColor;
    }

    .icon-close:hover {
        fill: $quaternaryColor;
    }

    // Icono de descarga
    .icon-download {
        width: 24px;
        height: auto;
        cursor: pointer;
        fill: $secondaryColor;
        margin-left: 10px;
    }

    .icon-download:hover {
        fill: $quaternaryColor;
    }
}

/* Estilo general para los botones */
.button-perla {
    background: linear-gradient(0deg, #ffffff, #eeeeee);
    border: none;
    border-radius: 30%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-weight: bold;
    margin: 0 10px;
    padding: 4px 12px;
    transition: 0.2s;
}

.button-perla:hover {
    background-color: #f2f2f2;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.button-perla:active {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    transform: scale(0.93);
}
