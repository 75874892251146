$principalColor: #3d4f65;
$secondaryColor: #f08a3b;
$tertiaryColor: #1d1d26;
$quaternaryColor: #323343;
$quaternaryColor: #323343;


.selector-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.company-block {
  flex: 1;
  padding: 20px;
  margin: 10px;
  border: 2px solid $secondaryColor;
  border-radius: 8px;
  cursor: pointer;
  // background-color: #fff;
  background-color: #272731;
}

.company-acronym {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
}

.company-description {
  font-size: 14px;
}

.continue-button {
  background-color: $secondaryColor;
  border: none;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  margin: auto;
  display: block;
  max-width: 200px;
  transition: background-color 0.3s ease;
}

.continue-button:hover {
  background-color: $secondaryColor;
}

.continue-button:disabled {
  opacity: 1;
  cursor: pointer;
  background-color: #f3a365;
}
