$principalColor: #3d4f65;
$secondaryColor: #f08a3b;
$tertiaryColor: #1d1d26;
$quaternaryColor: #323343;

.no-company-container {
    height: 500px; // Establece el alto deseado aquí
    background-color: $tertiaryColor;
    border-radius: 5px;
    border-color: $tertiaryColor;
    display: flex;
    justify-content: center; // Centrar contenido horizontalmente
    align-items: center; // Centrar contenido verticalmente

    .message {
        color: $secondaryColor;
        font-size: 24px; // Establece el tamaño de letra deseado aquí
    }
}
